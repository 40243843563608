import React from "react";
import "./Home2.css";
import TeamMemberCard from "./TeamMemberCard";
import Github from "./Github";
import Techstack from "./Techstack";
import Particle from "../Particle";
import Toolstack from "./Toolstack";
import aqsaImg from "../../Assets/Team/aqsa.jpg";
import nabeerImg from "../../Assets/Team/nabeer.jpg";
import sarwarImg from "../../Assets/Team/sarwar.jpg";
import gilangImg from "../../Assets/Team/Gilang Ferdiansyah.jpg";
import hibaImg from "../../Assets/Team/hiba.jpg";
import ArslanImg from "../../Assets/Team/Arsalan.png";
import JudeImg from "../../Assets/Team/jude.png";
import JustinImg from "../../Assets/Team/Justin.jpg";

function Home2() {
  const teamMembers = [
    { name: "Aqsa", title: "Frontend Developer", imgPath: aqsaImg },
    { name: "Nabeer", title: "Full Stack Developer", imgPath: nabeerImg },
    { name: "Justin", title: "UI Designer", imgPath: JustinImg },
    { name: "Jude", title: "React / Flask Developer", imgPath: JudeImg },
    { name: "Sarwar", title: "UX Designer", imgPath: sarwarImg },
    { name: "Gilang Ferdiansyah", title: "Proof Reader", imgPath: gilangImg },
    { name: "Hiba", title: "3D Modeler", imgPath: hibaImg },
    { name: "Arslan", title: "Backend intern", imgPath: ArslanImg },
  
    // { name: "Hamna", title: "3D Sculping Intern", imgPath: aqsaImg },
  ];

  return (
    <div className="home-section" id="about">
      <Particle />
      <div className="home-card-section">
      <div className="home-section__content">
        <h1 
       
        className="home-section__title">
           <br/>
          OUR <span className="home-section__title--highlight">TEAM</span> MEMBERS
        </h1>
        <div className="home-section__team-list">
          {teamMembers.map((member, index) => (
            <div className="home-section__team-item" key={index}>
              <TeamMemberCard imgPath={member.imgPath} name={member.name} title={member.title} />
            </div>
          ))}
        </div>
        </div>

        <div className="home-section__technologies">
          <br/>
          <h1 className="home-section__subheading">Technologies</h1>

          <Techstack />

          <h1 className="home-section__subheading">Tools We Use</h1>
          <Toolstack />

          <Github />
        </div>
      </div>
    </div>
  );
}

export default Home2;
