// import React from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import Particle from "../Particle";
// import Aboutcard from "./AboutCard";
// import Testimonials from './Testimonials';
// import laptopImg from "../../Assets/About/about.png";
// import AboutSection from './AboutSection';
// import './About.css';


// function About() {
//   return (
//     <Container fluid className="about-section">
//       <Particle />
//       <Container>
//         <Row > 
      
//           <Col 
//             md={7}
//             style={{
//               justifyContent: "center",
//               paddingTop: "30px",
//               paddingBottom: "50px",
//               // backgroundColor: "#f5f5f5"

//             }}
//           >
//             <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
//              Let Us Introduce <strong className="purple">Ourselves</strong>
//             </h1>
//             <br/>
//             <Aboutcard />
//           </Col>
//           <Col
//             md={5}
//             style={{ paddingTop: "120px", paddingBottom: "50px" }}
//             className="about-img"
//           >
//             <img src={laptopImg} alt="about" className="img-fluid" />
//           </Col>
//         </Row>
//         <AboutSection />
//         <br/>
//         <br/>
//         <br/>
//         <br/>
//         <Testimonials />
//       </Container>
//     </Container>
//   );
// }

// export default About;




import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Aboutcard from "./AboutCard";
import Testimonials from './Testimonials';
 import laptopImg from "../../Assets/About/about.png";
 import AboutSection from './AboutSection';
import './About.css';

function About() {
  return (
    <Container fluid className="about-section" style={{
      padding:"0px",
    }}>
      <Particle />
      <div className="about-full-width-bg">
        <Container>
          <Row>
            <Col 
              md={7} 
              className="about-col-content"
            >
              <h1 className="about-heading">
                Let Us Introduce <strong className="purple">Ourselves</strong>
              </h1>
              <br/>
              <Aboutcard />
            </Col>
            <Col
              md={5}
              className="about-img"
            >
              <img src={laptopImg} alt="about" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <AboutSection />
        <br />
        <br />
        <br />
        <br />
        <Testimonials />
      </Container>
    </Container>
  );
}

export default About;
