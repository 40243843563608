
import React, { useEffect, useState } from "react";
import ProjectCard from "./ProjectCards";
import "./ProjectCards.css";
import chatify from "../../Assets/Projects/chatify.png";
import bitsOfCode from "../../Assets/Projects/blog.png";
import editor from "../../Assets/Projects/codeEditor.png";
import emotion from "../../Assets/Projects/emotion.png";

function Projects() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const projects = [
    {
      imgPath: chatify,
      isBlog: false,
      title: "Sorla",
      description:
        "Designed a dynamic, responsive website for SORLA using React. The site emphasizes sustainability in residential and industrial sectors, showcasing eco-friendly practices while effectively communicating SORLA's mission and expertise.",
      ghLink: "",
    },
    {
      imgPath: bitsOfCode,
      isBlog: false,
      title: "Golnaz",
      description:
        "Created a responsive personal website for Golnaz using React, HTML, CSS, and JavaScript. The site includes sections for her portfolio, blog, and contact information, effectively showcasing her work and insights.",
      ghLink: "",
    },
    {
      imgPath: editor,
      isBlog: false,
      title: "Fitness",
      description:
        "Developed a responsive fitness website using React, HTML, CSS, and JavaScript. The site features sections for workout programs, nutrition advice, and motivational content, guiding users toward achieving their fitness goals effectively.",
      ghLink: "https://github.com/soumyajit4419/Editor.io",
    },
    {
      imgPath: emotion,
      isBlog: false,
      title: "Inventum-Digital",
      description:
        "Showcased our work on the Inventum website with a modern, responsive design using HTML, CSS, and JavaScript. The main page features a clean layout with a prominent header introducing the agency.",
      ghLink: "https://github.com/soumyajit4419/Editor.io",
    },
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % projects.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? projects.length - 1 : prevIndex - 1
    );
  };

  const getVisibleProjects = () => {
    const visibleProjects = [];
    for (let i = 0; i < 3; i++) {
      visibleProjects.push(projects[(currentIndex + i) % projects.length]);
    }
    return visibleProjects;
  };

  return (
    <div className="new-project-section">
      <h1 className="new-project-heading">
        My Recent <strong className="purple">Work</strong>
      </h1>
      <p>Here are a few projects we've worked on recently.</p>
      <div className="new-project-cards-container">
        <span className="arrow arrow-left" onClick={prevSlide}>
          &#8249;
        </span>
        {getVisibleProjects().map((project, index) => (
          <ProjectCard
            key={index}
            imgPath={project.imgPath}
            isBlog={project.isBlog}
            title={project.title}
            description={project.description}
            ghLink={project.ghLink}
          />
        ))}
        <span className="arrow arrow-right" onClick={nextSlide}>
          &#8250;
        </span>
      </div>
    </div>
  );
}

export default Projects;
