import React, { useState } from "react";
import emailjs from "emailjs-com";
import './Github.css';
import logo from "../../Assets/Navbar/pisrato Light Theme.png";

import sampleVector from '../../Assets/vector.png';


const Github = () => {

  
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    budget: "",
    message: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .send(
        'service_fa37aev', // Replace with your EmailJS service ID
        'template_qyft7ql', // Replace with your EmailJS template ID
        formData,
        'i50EIwSANWvJaBaZ4'   // Replace with your EmailJS public key
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert("Message sent successfully!");
        },
        (error) => {
          console.log("FAILED...", error);
          alert("Failed to send message. Please try again.");
        }
      );

    // Reset form after submission
    setFormData({
      name: "",
      email: "",
      phone: "",
      budget: "",
      message: ""
    });
  };
  return (
    <div className="git-section-layout">
      <div className="git-upper-container">
        <div className="git-vectorz-image">
          <img src={sampleVector} alt="Sample Vector" />
        </div>
      </div>

      <div className="cardz-container">
          <div className="cardz">
            <h2>Talk to us and get your project moving!</h2>
            <form onSubmit={sendEmail}>
            <div className="input-group">
              <input
                type="text"
                name="name"
                placeholder="Name*"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Email*"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="input-group">
              <input
                type="tel"
                name="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleChange}
              />
              <select
                name="budget"
                value={formData.budget}
                onChange={handleChange}
                required
              >
                <option value="">Your Budget*</option>
                <option value="$250 - $500">$250 - $500</option>
                <option value="$500 - $1000">$500 - $1000</option>
                <option value="$1000 - $5000">$1000 - $5000</option>
                <option value="$5000+">$5000+</option>
              </select>
            </div>
            <textarea
              name="message"
              placeholder="Enter your message*"
              value={formData.message}
              onChange={handleChange}
              required
            />
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>

      <div className="git-lower-container">
        <div className="git-footer-row">
          <div className="git-footer-logo">
          <img src={logo} alt="logo" />
          </div>
        </div>
        
        <div className="git-footer-row">
          <h3 className="purples">Quick Links</h3>
          <ul>
            <li>Home</li>
            <li>About</li>
            <li>Services</li>
            <li>Projects</li>
            <li>Careers</li>
            <li>Contact Us</li>
          </ul>
        </div>

        <div className="git-footer-row">
          <h3 className="purples">Services</h3>
          <ul>
            <li>Full Stack Development</li>
            <li>Mobile App Development</li>
            <li>Website Development</li>
            {/* <li>DevOps Consultancy</li> */}
            <li>Digital Marketing</li>
            <li>UI/UX Design</li>
          </ul>
        </div>

        <div className="git-footer-row">
          <h3 className="purples">Support</h3>
          <ul>
          <li><b>Contact:</b><br/> +92 315 5396990</li>
            <li><b>Email:</b><br/>nabeerdar1@gmail.com</li>
         
            {/* <li><b>Whatsapp at:</b><br/>+123 456 7890</li> */}
          </ul>
        </div>
      </div>
      
    </div>
  );
};

export default Github;
